import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import {Form} from '@evag/ec-react';

import '@evag/ec-react/dist/main.css';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function render({data}) {
  CampaignForm.channelId = data.site.meta.channel;

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        title={data.site.meta.title}
        description={data.site.meta.description}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>Em vez de taxar grandes fortunas, o governo federal, representado por Paulo Guedes, pretende taxar livros em 12%. Parece notícia do século passado, mas não é. Nesse momento de crise econômica mundial, em que vários países apresentam políticas de incentivo e proteção à cultura, o governo quer retroceder e criar um novo imposto – disfarçado de contribuição, pois é inconstitucional tributar livros – que aumenta a desigualdade social e restringe o acesso à cultura e à educação. O impacto será sentido diretamente pelos leitores e estudantes, mas, também, muitos autores, editoras e livrarias não irão sobreviver. As mulheres, maioria trabalhadora do mercado editorial, serão impactadas por mais essa política econômica nociva que destrói o ideal de democratização do livro. A Coletiva Virginia, um coletivo de mulheres trabalhadoras do mercado do livro e a Rede Emancipa se unem nessa luta contra o novo tributo de Guedes. Se você, assim como nós, é contra mais esse retrocesso, assine nosso manifesto. Nossa voz, somada a muitas outras, vai ecoar forte.</p>

          <p>#defendaolivro<br />
          #juntospelolivro<br />
          #impostonolivronão<br />
          #coletivavirginia</p>
        </div>
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Assine a petição</h2>

          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
