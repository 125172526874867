import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {InputCheckbox, InputLocation, InputText} from '@evag/ec-react';

import Button from './Button';

import styles from './CampaignForm.module.scss';

const CampaignForm = ({values, onChange, onBlur, setFieldValue}) => {
  const props = name => ({name, value: values[name], onChange, onBlur});
  return (
    <>
      <InputText
        className={styles.text}
        placeholder="Nome completo"
        {...props('name')}
      />
      <InputText
        className={styles.text}
        placeholder="E-mail"
        type="email"
        {...props('email')}
      />
      <InputText
        className={styles.text}
        cleave={{blocks: [0, 2, 9], delimiters: ['(', ') '], numericOnly: true}}
        placeholder="WhatsApp"
        type="tel"
        {...props('phone')}
      />
      <InputLocation
        className={styles.select}
        names={['uf', 'city']}
        setFieldValue={setFieldValue}
        {...{values, onChange, onBlur}}
      />

      <InputCheckbox
        className={styles.checkbox}
        label="Quero receber novidades da Rede Emancipa por e-mail."
        {...props('subscribe')}
      />

      <Button type="submit" fontSize="16px" width="100%">Enviar</Button>
    </>
  );
};

CampaignForm.initialValues = {
  name: '',
  email: '',
  phone: '',
  uf: '',
  city: '',
  subscribe: false,
};

CampaignForm.validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo Nome é obrigatório.'),
  email: Yup.string().email('Digite um e-mail válido.').required('O campo E-mail é obrigatório.'),
  phone: Yup.string(),
  uf: Yup.string().required('O campo Estado é obrigatório.'),
  city: Yup.string().required('O campo Cidade é obrigatório.'),
  subscribe: Yup.bool(),
});

CampaignForm.propTypes = {
  values: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

CampaignForm.captcha = true;
CampaignForm.element = CampaignForm;

export default CampaignForm;
